import './index.css';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router';
import { AxiosError } from 'axios';

import routes, { getUrl, injectCurrentRouteTracking } from 'routes';

import localization from 'lib/localization';

import { PERMISSIONS } from 'store/auth/permissions';
import {
  userColorThemeSelector,
  authPermissionsSelector,
  authMetaSelector,
  signOutAction
} from 'store/auth/reducer';
import { userLocaleSelector } from 'store/reducers/userSettingsReducer';

import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createTheme } from '@material-ui/core/styles';

import MainLayout from 'components/Layout/MainLayout';
import { ScreenLoader } from 'components/loader/ScreenLoader';

import { createResponseInterceptor } from 'lib/http/utils';

import { getTheme } from 'theme';
import { colorsThemes } from 'theme/colors';
import { ClientStorage } from 'lib/storage/Storage';
import { store } from 'store/store';

import { Buffer } from 'buffer';
(window as any).Buffer = (window as any).Buffer || Buffer;
(window as any).process = (window as any).process || {};

export const App = () => {
  const [interceptorsCreated, setInterceptorsCreated] = useState<boolean>(false)
  const history = useHistory();
  const location = useLocation();

  const locale = useSelector(userLocaleSelector);
  const permissions = useSelector(authPermissionsSelector);
  const userColorTheme = useSelector(userColorThemeSelector);
  const { isLoading } = useSelector(authMetaSelector);

  const theme = useMemo(() => createTheme(getTheme(userColorTheme as keyof typeof colorsThemes)), [userColorTheme]);

  const { routeElements } = useMemo(() => {
    const allowedRoutes = routes.filter((route) => {
      // Every restriction in route.restriction has to match with
      // redux.auth.user.permissions
      return !(Object.entries(route.restrictions!) as [PERMISSIONS, boolean][]).every(([permission, isAllowed]) => {
        return permissions[permission] !== isAllowed;
      });
    });

    // memoize component with injected tracking instead of creating new on each render
    const routeElements = allowedRoutes.map((route) => (
      <Route key={route.path} path={route.path} exact={route.exact} component={injectCurrentRouteTracking(route)} />
    ));

    return { allowedRoutes, routeElements };
  }, [permissions]);

  useEffect(() => {
    if (interceptorsCreated) {
      return;
    }

    setInterceptorsCreated(true);

    const onPasswordRejected = (err: AxiosError) => {
      const { response } = err;
      if (response?.status === 401) {
        if ((response?.data as any).error_type === 'user_password_expired') {
          const updatePasswordUrl = getUrl('authUpdatePassword');
          if (location.pathname !== updatePasswordUrl) {
            history.push(updatePasswordUrl);
          }
        }
        if ((response?.data as any).error_type === 'user_session_expired') {
          ClientStorage.setItem('user_session_expired', 'true');
          store.dispatch(signOutAction())
        }
      }

      return Promise.reject(err);
    };

    createResponseInterceptor(undefined, onPasswordRejected);
  }, [history, location]);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale={locale} messages={localization[locale]}>
        <MainLayout>
          <Suspense
            fallback={
              <Suspense fallback={<div />}>
                <ScreenLoader />
              </Suspense>
            }
          >
            {isLoading ? (
              <ScreenLoader />
            ) : (
              <Switch>
                {routeElements}

                <Route
                  render={({ location }) => {
                    return (
                      <Redirect
                        to={{
                          pathname: getUrl('signIn'),
                          state: { from: location },
                        }}
                      />
                    );
                  }}
                />
              </Switch>
            )}
          </Suspense>
        </MainLayout>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
